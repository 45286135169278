










































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { apiOrderPrint } from "@/api/application/print";
import LsDialog from "@/components/ls-dialog.vue";
import LsPagination from "@/components/ls-pagination.vue";
import OrderLogistics from "@/components/order/order-logistics.vue";
import {
    apiOrderRemarks,
    apiOrderCancel,
    apiOrderConfirm,
} from "@/api/order/order";
import { apiSelffetchVerification } from "@/api/application/selffetch";
import OrderChidren from "@/components/order/order-children.vue";

@Component({
    components: {
        LsDialog,
        LsPagination,
        OrderLogistics,
        OrderChidren,
    },
})
export default class OrderPane extends Vue {
    @Prop() value: any;
    @Prop() pager!: any;
    @Prop() store_name: any;
    @Prop({ default: true }) is_contact!: boolean; //是否显示收获人信息
    @Prop({ default: "goodsOrderList" }) backQuery!: string; //是否显示收获人信息

    selectIds: any = [];

    remarks = ""; //商家备注

    dialogVisible: boolean = false;
    children_id: string = "";

    // 获取订单信息
    getOrderLists() {
        (this.$parent as any).getOrderLists();
    }

    // 选择某条数据
    selectionChange(val: any[]) {
        this.selectIds = val.map((item) => item.id);
    }

    // 全选
    selectAll() {
        (this.$refs.paneTable as any).toggleAllSelection();
    }

    // 去订单详情
    toOrder(id: any) {
        this.$router.push({
            path: "/trade/order_detail",
            query: { id, backQuery: this.backQuery },
        });
    }

    toUser(id: any) {
        this.$router.push({
            path: "/user/user_details",
            query: { id: id },
        });
    }

    // 取消订单
    orderCancel(id: Number) {
        apiOrderCancel({ id: id }).then((res) => {
            this.getOrderLists();
        });
    }

    // 确认收货
    orderConfirm(id: Number) {
        apiOrderConfirm({ id: id }).then((res) => {
            this.getOrderLists();
        });
    }

    async onPrintOrderFunc(row: any) {
        await apiOrderPrint({ id: row.id });
    }

    // 商家备注
    postOrderRemarks(id: any) {
        id = Array.isArray(id) == true ? id : this.selectIds;
        apiOrderRemarks({
            id: id,
            order_remarks: this.remarks,
        }).then((res) => {
            this.remarks = "";
            this.getOrderLists();
        });
    }

    // 订单核销
    onSelffetchOrderVerification(id: number) {
        apiSelffetchVerification({
            id,
        }).then(() => {
            // 订单核销成功就请求新列表
            this.getOrderLists();
        });
    }

    orderChidrenClick(id: string) {
        this.dialogVisible = true;
        this.children_id = id;
    }
}
