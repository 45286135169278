






























































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import { apiGetSubOrderInfo } from "@/api/order/order";

@Component({
  components: {
    LsDialog,
  },
})
export default class OrderChidren extends Vue {
  @Prop({ default: "" }) id!: string | number;

  // 订单数据
  orderData: any = {
    admin_order_btn: {
      remark_btn: 1,
      cancel_btn: 0,
      confirm_btn: 0,
      logistics_btn: 0,
      refund_btn: 0,
      address_btn: 1,
      price_btn: 1,
    },
  };

  address: any = {
    province_id: "", //必填	int	所在地区:省id
    city_id: "", //必填	int	所在地区:市id
    district_id: "", //必填	int	所在地区:区id
    address: "", //必填	varchar	详细地址
  };

  // 商家备注
  remarks: String = "";

  // 运费更改
  express_price: String = "";

  // 商品价格
  goods_price: String = "";

  // 获取订单详情
  getOrderDetail() {
    apiGetSubOrderInfo({ id: this.id }).then((res) => {
      this.orderData = res;
    });
  }

  // 商品信息的底部结算信息
  getSummaries(param: any) {
    const { columns, data } = param;
    const sums: any = [];
    columns.forEach((column: any, index: any) => {
      if (index === 0) {
        sums[0] = "总价";
        return;
      }
      const values = data.map((item: any) => Number(item[column.property]));
      if (!values.every((value: any) => isNaN(value))) {
        if (index == 1) {
          return;
        }
        sums[index] = values.reduce((prev: any, curr: any) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          }
          return prev;
        }, 0);
        if (index !== 3) {
          if (typeof sums[index] == "number") {
            sums[index] = sums[index].toFixed(2);
          }
          sums[index] = "¥" + sums[index];
        }
        if (index == 5) {
          if (typeof sums[index] == "number") {
            sums[index] = sums[index].toFixed(2);
          }
          sums[index] = "-" + sums[index];
        }
        if (index == 6) {
          sums[index] = "¥" + this.orderData.total_goods_pay_price.toFixed(2);
        }
      }
    });
    return sums;
  }

  created() {
    this.getOrderDetail();
  }
}
